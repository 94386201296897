<template>
	<div>
		<button v-on:click="authenticated">Click</button>
	</div>
</template>

<script>
	import store from '@/store'
	export default{
		setup(){
			const authenticated = async () => {
				let tes = store.getters['auth/authenticated']
	      		console.log(tes)

			}

			return {
				authenticated	
			}
		}
	}
	
</script>